<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12" class="px-0">
                    <simple-table
                        ref="tabla"
                        :isSelectable="false"
                        :rows="datosTabla"
                        :columns="headers"
                        :perPage="[10, 25, 50, 100]"
                        :tableName="'Dispositivos biométricos'"
                        :valCheck="'id'"
                        :idTabla="'tablaDispositivos'"
                        :loading="loadingProcesar"
                        @filtroCampo="filtroCampo"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                            <div
                                class="icon-container d-flex align-center justify-center"
                                @click="openModal()"
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }" class="tablaBody">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td>
                                    <div>
                                        <div class="tblPrincipal">{{ item.nombre_cliente }}</div>
                                        <div class="tblSecundario">{{ item.nombre_empresa }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.conexion }}</div>
                                </td>
                                <template>
                                    <td>
                                        <div class="tblOpciones">
                                            <v-tooltip bottom v-if="item.tipo == 'fisico'">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        small
                                                        dark
                                                        @click="empleados(item)"
                                                        slot="activator"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">list</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Ver Empleados</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-else>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        slot="activator"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        @click="resetPass( item)"
                                                        small
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">lock</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Restaurar contraseña</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        @click="abrirModal('update', item)"
                                                        small
                                                        slot="activator"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-if="permisos.delete">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        class="botonHover"
                                                        icon
                                                        fab
                                                        dark
                                                        small
                                                        @click="eliminar(item.id, item.empresa_id, item.tipo)"
                                                        slot="activator"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </simple-table>
                </v-col>
            </v-row>
        </v-container>

        <template class="borde-card">
            <v-dialog v-model="dialogFilter" persistent max-width="700px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalFilter"></v-btn>
                </template>
                <v-card class="borde-card">
                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                            <div class="headerModal-filter">
                                <h2 class="titleModalBuscar">{{ "Búsqueda avanzada" }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-if="isLoadingModal" class="d-flex justify-center">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-if="!isLoadingModal" grid-list-md id="contenedor">
                                <v-row class="pt-0 mt-0">
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            :items="clientes"
                                            item-text="nombre"
                                            item-value="id"
                                            label="Cliente"
                                            persistent-hint
                                            v-model="cliente_value"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            :items="empresas"
                                            item-text="nombre"
                                            item-value="id"
                                            label="Empresa"
                                            persistent-hint
                                            v-model="empresa_value"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            :items="sucursales"
                                            item-text="nombre"
                                            item-value="id"
                                            label="Sucursal"
                                            persistent-hint
                                            v-model="sucursal_value"
                                        />
                                    </v-col>

                                    <v-col xs="12" sm="6" md="6" class="py-0" >
                                        <v-text-field
                                            outlined
                                            label="Nombre"
                                            class="estiloLetras"
                                            v-model="advancedSearch.nombre"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col xs="12" sm="6" md="6" class="py-0">
                                        <v-text-field
                                            outlined
                                            label="Número de serie"
                                            class="estiloLetras"
                                            v-model="advancedSearch.serie"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModalBuscar()" 
                                :disabled="isSearch"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="buscar()"
                                :loading="isSearch"
                            >
                                Buscar
                            </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-toolbar-title v-on="on">
                            <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                        </v-toolbar-title>
                    </template>

                    <perfect-scrollbar style="background:#FFF">
                        <v-card elevation="0">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ titulo_modal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto"> 
                                    <div id="loadingModal" v-show="!finaliza_carga">
                                        <div id="loading">
                                        <v-progress-circular
                                            indeterminate
                                            :size="100"
                                            :width="7"
                                            color="blue"
                                        ></v-progress-circular>
                                        </div>
                                    </div>

                                    <v-container v-show="finaliza_carga" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <template v-if="rol == 'root'">
                                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            :error-messages="errors"
                                                            required
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            :error-messages="errors"
                                                            required
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'admin'">
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            :error-messages="errors"
                                                            required
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="sucursales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Sucursal"
                                                            persistent-hint
                                                            v-model="sucursal_value"
                                                            :error-messages="errors"
                                                            required
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template
                                                v-if=" 
                                                    rol == 'root' || 
                                                    rol == 'admin' ||
                                                    rol == 'admin-empresa' ||
                                                    rol == 'admin-sucursal'
                                                "
                                            >
                                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Departamento" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="departamentos"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Departamento"
                                                            persistent-hint
                                                            v-model="dispositivo.departamento_id"
                                                            :error-messages="errors"
                                                            required
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        id="primero"
                                                        label="Nombre"
                                                        v-model="dispositivo.descripcion"
                                                        :error-messages="errors"
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="accion == 'add'" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0 pb-0 ma-0">
                                                <template>
                                                    <v-container class="ma-0 pa-0">
                                                        <h4 class="tituloRadio pb-4">Tipo de conexión</h4>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
                                                            <v-radio-group class="ma-0 pa-0 " v-model="dispositivo.tipo" row>
                                                                <v-radio class="col-5 pa-0" label="Checador facial" value="facial"></v-radio>
                                                                <v-radio class="col-5 pa-0" label="Checador de huella" value="fisico"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                    </v-container>
                                                </template>
                                            </v-col>
                                            <v-col v-if="dispositivo.tipo == 'fisico'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Número de serie" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Número de serie"
                                                        v-model="dispositivo.serial"
                                                        :error-messages="errors"
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="dispositivo.tipo == 'fisico'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Direccion IP" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Direccion IP"
                                                        v-model="dispositivo.ip"
                                                        :error-messages="errors"
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="dispositivo.tipo == 'facial'" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Correo" rules="required|email">
                                                    <v-text-field
                                                        outlined
                                                        label="Correo"
                                                        v-model="dispositivo.ip"
                                                        :error-messages="errors"
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions v-show="finaliza_carga" class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()"
                                        :loading="loading_guardar"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()"
                                        :loading="loading_guardar"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>

                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </v-layout>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Notify from "@/plugins/notify";
import Datatable from "@/components/datatable/Datatable.vue";
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import clientesApi from "../api/clientes";
import empresaApi from "../api/empresas";
import sucursalApi from "../api/sucursales";
import departamentoApi from "../api/departamentos";
import dispositivosApi from "../api/dispositivos";
import PerfectScrollbar from "perfect-scrollbar";
import userApi from "@/api/usuarios";

export default {
    components: {
        "data-table": Datatable,
        "simple-table": DatatableSimple,
    },
    data() {
        return {
            url: "reloj-checador/find",
            columns: [
                { 
                    label: "Cliente", 
                    name: "cliente", 
                    align: "left", 
                    filterable: true 
                },
                {
                    label: "Nombre",
                    name: "decripcion",
                    align: "left",
                    filterable: true,
                },
                {
                    label: "Sucursal",
                    name: "sucursal",
                    align: "left",
                    filterable: true,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: "center",
                    filterable: false,
                },
            ],
            filters: {
                activo          : true,
                paginate        : true,
                include_padres  : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                order_by        : 'tipo',
            },
            advancedSearch: {
                nombre  : null,
                serie   : null,
            },
            loading_guardar     : false,
            dialog              : false,
            datosLogin          : "",
            dispositivo: {
                id              : 0,
                serial          : null,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                ip              : null,
                descripcion     : null,
                empleados       : [],
                tipo            : null,
            },
            empresa_id          : null,
            sucursal_id         : null,
            departamento_id     : null,
            clientes            : [],
            empresas            : [],
            sucursales          : [],
            departamentos       : [],
            titulo_modal        : "",
            finaliza_carga      : false,
            parametros_base     : { activo: true, paginate: false },
            accion              : "",
            cliente_value       : null,
            empresa_value       : null,
            sucursal_value      : null,
            rol                 : "",
            roles               : [],
            url_checador        : "",
            token_checador      : "",
            error_asp           : false,
            imagen              : "/static/modal/dispositivoCreate.svg",
            datosTabla          : [],
            headers             : [
                { label: "Cliente",         name: "cliente",        filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Nombre",          name: "descripcion",    filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Conexión",        name: "conexion",       filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Opciones",        name: "opciones",       filterable: false,      showTooltip: false,     align: "left",    mensajeTooltip:"" },
                
            ],
            loadingProcesar     : false,
            dialogFilter        : false,
            isLoadingModal      : false,
            isSearch            : false,
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        };
    },
    computed: {
        ...mapState("auth", ["datos", "user"]),
        imagenDispositivo() {
            return "/static/bg/dispositivo.png";
        },
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.cambiaCliente(val);
            }
        },
        empresa_value: function(val) {
            if(val != null){
                this.cambiaEmpresa(val);
            }
        },
        sucursal_value: function(val) {
            if(val != null){
                this.cambiaSucursal(val);
            }
        },
    },
    methods: {
        /**
         * @method listar Método para listar en tabla
         * @description Este metodo sirve para listar los dispositivos o usuarios en la tabla segun sea el rol
         */
        listar() {
            let server_asp = this.$session.get("checador_asp");

            this.resetValores();
            this.token_checador = localStorage.getItem("token_checador");
            this.datosLogin = this.$session.get("usuario");
            this.empresas = this.datosLogin.empresas;
            this.rol = this.datosLogin.rol.name;

            if (this.rol == "root") {
                this.columns = [
                    { label: "Cliente", name: "cliente", filterable: true },
                    { label: "Nombre", name: "descripcion", filterable: true  },
                    { label: "Conexión", name: "conexion", filterable: true  },
                    { label: "Opciones", value: "opciones", align: "center" },
                ];
                this.getDispositivos();
            } else if (this.rol == "admin") {
                this.columns = [
                    { label: "Cliente", name: "cliente", filterable: true  },
                    { label: "Nombre", name: "descripcion", filterable: true  },
                    { label: "Conexión", name: "conexion", filterable: true  },
                    { label: "Opciones", value: "opciones", align: "center" },
                ];
                this.dispositivo.cliente_id = this.filters.cliente_id = this.datosLogin.cliente_id;
                this.getDispositivos(this.datosLogin.cliente_id);
            } else if (this.rol == "admin-empresa") {
                this.columns = [
                    { label: "Cliente", name: "cliente", filterable: true  },
                    { label: "Nombre", name: "descripcion",filterable: true  },
                    { label: "Conexión", name: "conexion", filterable: true  },
                    { label: "Opciones", value: "opciones", align: "center" },
                ];
                this.dispositivo.empresa_id = this.filters.empresa_id = this.datosLogin.empresa_id;
                this.getDispositivos(this.datosLogin.cliente_id, this.datosLogin.empresa_id);
            } else if (this.rol == "admin-sucursal") {
                this.columns = [
                    { label: "Cliente", name: "cliente", filterable: true  },
                    { label: "Nombre", name: "descripcion", filterable: true  },
                    { label: "Conexión", name: "conexion", filterable: true  },
                    { label: "Opciones", value: "opciones", align: "center" },
                ];
                this.dispositivo.sucursal_id = this.filters.sucursal_id = this.datosLogin.sucursal_id;
                this.getDispositivos(this.datosLogin.cliente_id, this.datosLogin.empresa_id, this.datosLogin.sucursal_id);
            }

        },

        /**
         * @method setFilters Método para el filtrado.
         * @description Este método se ejecuta cuando se le da click al botón "buscar". Se realiza la petición con base a los campos solicitados.
         */
        setFilters() {
            let nombre = this.advancedSearch.nombre;
            let serie = this.advancedSearch.serie;

            let filterParams = {
                descripcion: nombre,
                serial: serie,
            };

            let defaultParams   = {
                activo          : true,
                paginate        : true,
                include_padres  : true,
                cliente_id      : this.datosLogin.cliente_id,
                empresa_id      : this.datosLogin.empresa_id,
                sucursal_id     : this.datosLogin.sucursal_id,
                departamento_id : this.datosLogin.departamento_id,
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.filters = defaultParams;

            this.advancedSearch.nombre = null;
            this.advancedSearch.serie = null;
        },

        /**
         * @method abrirModal Método para abrir el modal de conexiones(dispositivos)
         * @description Método para abrir el modal de conexiones o dispositivos. Modal para agregar o actualizar una conexión o dispositivo.
         * @param {string} accion La acción puede ser "add" o "update"
         * @param {object} item Objeto que esta en null si la acción es "add"; item tiene los datos de una conexión o dispositivo para que se pinten en el modal.
         */
        async abrirModal(accion, item = null) {
            await this.resetValores();
            this.accion = accion;
            if (accion == "add") {
                this.titulo_modal = "Registrar tipo de conexión";
                this.imagen = "/static/modal/conexionCreate.svg";
                this.dispositivo.tipo = "facial";
            } 
            else if ((accion = "update")) {
                this.titulo_modal = "Actualizar tipo de conexión";
                this.imagen = "/static/modal/conexionUpdate.svg";
                this.dispositivo.id             = item.id;
                this.dispositivo.serial         = item.serial;
                this.dispositivo.ip             = item.conexion;
                this.dispositivo.descripcion    = item.nombre;
                this.cliente_value              = item.cliente_id;
                this.empresa_value              = item.empresa_id;
                this.sucursal_value             = item.sucursal_id;
                this.dispositivo.departamento_id= item.departamento_id;
                this.dispositivo.tipo           = item.tipo;

                if(item.serial == 'fisico'){
                    this.dispositivo.serial = item.serial;
                }
                else{
                }

                if (item.cliente != undefined) {
                    this.dispositivo.cliente_id = item.cliente_id;
                }
                if (item.empresa != undefined) {
                    this.dispositivo.empresa_id = item.empresa_id;
                }
                if (item.sucursal != undefined) {
                    this.dispositivo.sucursal_id = item.sucursal_id;
                }
                if (item.departamento != undefined) {
                    this.dispositivo.departamento_id = item.departamento_id;
                }
            }

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
            }
            if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            if (this.rol == "admin-sucursal") {
                this.cambiaSucursal(this.datosLogin.sucursal_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            this.finaliza_carga = true;
            setTimeout(function() {
                document.getElementById("btnModal").click();
            }, 100);
        },

        /**
         * @method getClientes Método para clientes
         * @description Método para hacer petición a la API de clientes
         */
        getClientes(clientes = null) {
            let param = { activo: true, paginate: false };
            clientesApi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        /**
         * @method getEmpresas Método para empresas
         * @description Método para hacer petición a la API de empresas. Se ejecuta despues de elegir un cliente.
         */
        getEmpresas() {
            let parametros = { activo: true, paginate: false };
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method cambiaCliente Método para empresas
         * @description Método para hacer petición a la API de empresas. Se ejecuta despues de elegir un cliente.
         */
        cambiaCliente(cliente, accion = null) {
            let param = { activo: true, paginate: false, cliente_id: cliente };
            empresaApi.getEmpresas(param).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method cambiaEmpresa Método para sucursales
         * @description Método para hacer petición a la API de susursales. Se ejecuta despues de elegir una empresa.
         */
        cambiaEmpresa(empresa, accion = null) {
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method cambiaSucursal Método para departamentos
         * @description Método para hacer petición a la API de departamentos. Se ejecuta despues de elegir una sucursal.
         */
        cambiaSucursal(sucursal, accion = null) {
            let parametros = { activo: true, paginate: false, sucursal_id: sucursal };
            departamentoApi.getDepartamentos(parametros).then((response) => {
                this.departamentos = response.data;
            })
            .catch((err) => {
                this.loading_guardar = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            });
        },

        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y resetea los valores de dispositivos.
         */
        async cerrarModal() {
            this.dialog                     = false;
            this.dispositivo.id             = 0;
            this.dispositivo.serial         = null;
            this.dispositivo.cliente_id     = null;
            this.dispositivo.empresa_id     = null;
            this.dispositivo.sucursal_id    = null;
            this.dispositivo.departamento_id= null;
            this.dispositivo.ip             = null;
            this.dispositivo.descripcion    = null;
            this.dispositivo.tipo           = null;
            this.cliente_value              = null;
            this.empresa_value              = null;
            this.sucursal_value             = null;

            this.clientes                   = [];
            this.empresas                   = [];
            this.sucursales                 = [];
            this.departamentos              = [];

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                } 
            });
        },

        /**
         * @method resetValores Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        resetValores() {
            this.dialog                     = false;
            this.dispositivo.id             = 0;
            this.dispositivo.serial         = null;
            this.dispositivo.cliente_id     = null;
            this.dispositivo.empresa_id     = null;
            this.dispositivo.sucursal_id    = null;
            this.dispositivo.departamento_id= null;
            this.dispositivo.ip             = null;
            this.dispositivo.descripcion    = null;
            this.dispositivo.tipo           = null;
            this.cliente_value              = null;
            this.empresa_value              = null;
            this.sucursal_value             = null;
            this.loading_guardar            = false;

            this.clientes = [];
            this.empresas = [];
            this.sucursales = [];
            this.departamentos = [];
        },

        /**
         * @method guardar Este método guarda los datos que se necesitan para el registro de una conexión(dispositivo).
         * @description Este método guarda los datos que se necesitan para una conexión.
         * El método se llama para crear o actualizar un registro para una conexión. En caso de que la petición
         * sea correcta la tabla se actualiza.
         */
        guardar() {
            let self = this;
            this.dispositivo.cliente_id = this.cliente_value;
            this.dispositivo.empresa_id = this.empresa_value;
            this.dispositivo.sucursal_id = this.sucursal_value;

            if(this.dispositivo.tipo == 'facial'){
                this.dispositivo.serial = "";
            }

            this.$refs.form.validate().then(result => {
                if (result) {
                    this.loading_guardar = true;
                    if (this.accion === "add") {
                        dispositivosApi
                        .addDispositivo(this.dispositivo)
                        .then((response) => {
                            self.listar();
                            self.respuesta_ok("Se creó el registro del dispositivo satisfactoriamente.");
                            //this.$refs.tableDispositivos.getData();
                        })
                        .catch((err) => {
                            this.respuesta_error(err);
                        });
                    } 
                    else if (this.accion === "update") {
                        dispositivosApi
                        .updateDispositivo(this.dispositivo)
                        .then((response) => {
                            self.listar();
                            self.respuesta_ok("Se actualizo el dispositivo satisfactoriamente.");
                            //this.$refs.tableDispositivos.getData();
                        })
                        .catch((err) => {
                            self.respuesta_error(err);
                        });
                    }
                }
            });
        },

        /**
         * @method eliminar Método que elimina una conexión(usuario o dispositivo)
         * @description Método que elimina una conexión (usuario o dispositivo)
         * @param {int} id Id de la conexión (usuario o dispositivo) a eliminar
         * @param {int} empresa_id Id de la empresa.
         * @param {string} tipo tipo de conexión (usuario o dispositivo)
         */
        eliminar(id, empresa_id, tipo) {
            let self = this;
            let servidor_asp = localStorage.getItem("checador_asp");
            if (servidor_asp != "false") {
                Notify.Alert(
                    "ADVERTENCIA",
                    "¿Estás seguro que deseas eliminar el registro?",
                    "Eliminar",
                    () => {
                        let param = { id: id, empresa_id: empresa_id, tipo: tipo };
                        dispositivosApi.eliminarDispositivo(param).then((response) => {
                            self.listar();
                            //this.$refs.tableDispositivos.getData();
                            Notify.Success(
                                "Registro eliminado",
                                "El registro ha sido eliminado satisfactoriamente"
                            );
                        })
                        .catch((err) => {
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                        });
                    }
                );
            } 
            else {
                Notify.Alert(
                    "ERROR",
                    "No se ha podido conectar con el servidor de checadores, favor de contactar al administrador.",
                    "Aceptar",
                    () => {
                        
                    }
                );
            }
        },

        /**
         * @method empleados Método que redirecciona a la vista relación de checador-empleados
         * @description Método que redirecciona a la vista relación de checador-empleados
         * @param {object} data datos del dispositivo
         */
        empleados(data) {
            this.$session.remove("emplChecador");
            let valor = { datos: data };
            this.$session.set("emplChecador", valor);
            this.$router.push("/checadorRelacion");
        },

        /**
         * @method respuesta_error Método para mensaje de errores
         * @description Método para mostrar los errores mediante una notificación.
         * @param {string, object} err El error puede ser de tipo string o de tipo objeto. 
         */  
        respuesta_error(err) {
            this.loading_guardar = false;
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },

        /**
         * @method respuesta_ok Método para mostrar un mensaje exitoso
         * @description Método para mostrar un mensaje de opercación exitosa.
         * @param {string} msj Mensaje para mostrar en modal. 
         */
        respuesta_ok(msj) {
            this.cerrarModal();
            Notify.Success("Operación exitosa", msj);
        },

        /**
         * @method init Método que se ejecuta al montar la vista
         */
        init() {
            
        },

        /**
         * @method resetPass Método para restaurar la contgraseña
         * @description Método para restaurar la contraseña de laa conexión de tipo usuario.
         */
        resetPass(data = null) {
            let usuario = {
                id                  : data.id,
                nombre              : data.nombre,
                apellido_paterno    : data.apellido_paterno,
                apellido_materno    : data.apellido_materno,
                telefono_oficina    : data.telefono_oficina,
                telefono            : data.telefono,
                email               : data.email,
                password            : "123456",
                cliente_id          : data.cliente_id,
                empresa_id          : data.empresa_id,
                sucursal_id         : data.sucursal_id,
                departamento_id     : data.departamento_id,
                primer_inicio_sesion: true,
                rol_id              : 7,
                email_anterior      : data.email,
                departamentos       : [],
            };
            Notify.Restore(
                "¿Quieres restaurar contraseña?",
                null,
                "Restaurar",
                () => {
                    userApi.updatePassword(usuario).then((response) => {
                        Notify.Success(
                            "Contraseña restaurada",
                            "La contraseña se restauró satisfactoriamente."
                        );
                    })
                    .catch((error) => {
                        console.error(err);
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            );
        },

        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa, admimn-sucursal
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },


        async getDispositivos( cliente_id = null, empresa_id = null, sucursal_id = null, nombre = null, serie = null, order_by = null, order = null){
            this.loadingProcesar = true;
            let parametros = { 
                cliente_id      : cliente_id, 
                empresa_id      : empresa_id, 
                sucursal_id     : sucursal_id,
                descripcion     : nombre,
                serial          : serie,
                order_by        : order_by,
                order           : order,
                activo          : true
            };
            await dispositivosApi.getDispositivos(parametros).then((response) => {
                this.datosTabla = response.data;
                this.loadingProcesar = false;
            })
            .catch((err) => {
                this.loadingProcesar = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },

        async openModal(){
            this.dialogFilter = true;
            this.advancedSearch.nombre = null;
            this.advancedSearch.serie = null;
            await this.cerrarModal();

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
            }
            if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            if (this.rol == "admin-sucursal") {
                this.cambiaSucursal(this.datosLogin.sucursal_id, this.accion);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
        },
        async filtroCampo(columnaOrden){
            
            await this.getDispositivos(
                this.datosLogin.cliente_id, 
                this.datosLogin.empresa_id, 
                this.datosLogin.sucursal_id, 
                this.advancedSearch.nombre, 
                this.advancedSearch.serie,
                columnaOrden.order_by,
                columnaOrden.dir
            );
            

        },
        async cerrarModalBuscar(){
            this.dialogFilter = false;
        },
        async buscar(){
            if (this.rol == "root") {
                await this.getDispositivos(this.cliente_value, this.empresa_value, this.sucursal_value, this.advancedSearch.nombre, this.advancedSearch.serie);
                await this.cerrarModalBuscar();
            } else if (this.rol == "admin") {
                await this.getDispositivos(this.datosLogin.cliente_id, this.empresa_value, this.sucursal_value, this.advancedSearch.nombre, this.advancedSearch.serie);
                await this.cerrarModalBuscar();
            } else if (this.rol == "admin-empresa") {
                await this.getDispositivos(this.datosLogin.cliente_id, this.datosLogin.empresa_id, this.sucursal_value, this.advancedSearch.nombre, this.advancedSearch.serie);
                await this.cerrarModalBuscar();
            } else if (this.rol == "admin-sucursal") {
                await this.getDispositivos(this.datosLogin.cliente_id, this.datosLogin.empresa_id, this.datosLogin.sucursal_id, this.advancedSearch.nombre, this.advancedSearch.serie);
                await this.cerrarModalBuscar();
            }
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("reloj-checador.store")
            this.permisos.update = permisosStorage.includes("reloj-checador.update")
            this.permisos.delete = permisosStorage.includes("reloj-checador.delete")
        }
    },

    /**
     * @description mounted(). Se inician los valores que se requieren al montar los componentes en la vista.
    */
    mounted() {
        this.init();
    },

    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    created() {
        this.listar();
        this.permisosVista();
    },
};
</script>
<style scoped>
    #btnModalFilter{
        display: none;
    }
    .tituloRadio {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }
    .icon-container {
        width: 42px;
        height: 42px;
        background: #ffffff;
        border-radius: 100%;
        text-align: center;
        padding: 11px;
        transition:0.3s;
    }

    .icon-container:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    .titleModalBuscar {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
        padding-bottom: 25px;
    }

</style>
